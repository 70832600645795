import React, { useState } from "react"
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import { Link } from "react-router-dom"
import MenuIcon from "@mui/icons-material/Menu"
import LiquorIcon from "@mui/icons-material/Liquor"
import SettingsIcon from "@mui/icons-material/Settings"
import SearchIcon from "@mui/icons-material/Search"
import CodeIcon from "@mui/icons-material/Code"
import PeopleIcon from "@mui/icons-material/People"

const pages = [
  {
    name: "Drink Search",
    link: "/",
    icon: <SearchIcon />,
    requiresLogin: false,
    requiresAdmin: false,
  },
  {
    name: "My Bar",
    link: "/MyBar",
    icon: <LiquorIcon />,
    requiresLogin: true,
    requiresAdmin: false,
  },
  {
    name: "Admin Page",
    link: "/Admin",
    icon: <SettingsIcon />,
    requiresLogin: true,
    requiresAdmin: true,
  },
  {
    name: "User Management",
    link: "/UserManagement",
    icon: <PeopleIcon />,
    requiresLogin: true,
    requiresAdmin: true,
  },
  {
    name: "Test",
    link: "/Test",
    icon: <CodeIcon />,
    requiresLogin: true,
    requiresAdmin: true,
  },
]

export default function NavMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  return (
    <>
      <IconButton
        aria-label="page menu"
        sx={{
          "&.Mui-disabled": {
            color: "transparent",
          },
        }}
        disabled={props.loggedInStatus !== "LOGGED_IN"}
        size="large"
        edge="start"
        onClick={handleOpen}
      >
        <MenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.user.admin
          ? pages.map((page) => (
              <Link key={page.name} to={page.link} onClick={handleClose}>
                <MenuItem>
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText primary={page.name} />
                </MenuItem>
              </Link>
            ))
          : props.loggedInStatus === "LOGGED_IN" && !props.user.admin
          ? pages
              .filter((page) => !page.requiresAdmin)
              .map((page) => (
                <Link key={page.name} to={page.link} onClick={handleClose}>
                  <MenuItem>
                    <ListItemIcon>{page.icon}</ListItemIcon>
                    <ListItemText primary={page.name} />
                  </MenuItem>
                </Link>
              ))
          : pages
              .filter((page) => !page.requiresLogin)
              .map((page) => (
                <Link key={page.name} to={page.link} onClick={handleClose}>
                  <MenuItem>
                    <ListItemIcon>{page.icon}</ListItemIcon>
                    <ListItemText primary={page.name} />
                  </MenuItem>
                </Link>
              ))}
      </Menu>
    </>
  )
}
