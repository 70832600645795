import React, { useState } from "react"
import { Avatar, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import { Link } from "react-router-dom"
import Login from "../auth/Login"
import AccountIcon from "@mui/icons-material/AccountCircle"
import PersonIcon from "@mui/icons-material/Person"
import LogoutIcon from "@mui/icons-material/Logout"

export default function NavMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const handleLogoutClick = () => {
    localStorage.removeItem("cocktail_curator_token")
    window.location.reload()
  }

  const menuOptions = [
    {
      link: "/Account",
      icon: <AccountIcon />,
      text: props.user.first_name
        ? `${props.user.first_name} ${props.user.last_name}`
        : props.user.email,
    },
    {
      icon: <LogoutIcon />,
      text: "Log Out",
    },
  ]

  return (
    <Box sx={{ flexGrow: 1 }} textAlign="right">
      <IconButton aria-label="user account menu" size="large" edge="end" onClick={handleOpen}>
        <Avatar
          sx={{
            bgcolor: "rgba(0, 0, 0, 0.54)",
            width: 32,
            height: 32,
            fontWeight: "500",
          }}
        >
          {props.loggedInStatus === "LOGGED_IN" ? props.user.first_name.charAt(0) : <PersonIcon />}
        </Avatar>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.loggedInStatus === "LOGGED_IN" ? (
          menuOptions.map((option, index) => {
            return option.link ? (
              <Link key={index} to={option.link} onClick={handleClose}>
                <MenuItem>
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <ListItemText primary={option.text} />
                </MenuItem>
              </Link>
            ) : (
              <MenuItem key={index} onClick={handleLogoutClick}>
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText primary={option.text} />
              </MenuItem>
            )
          })
        ) : (
          <Login {...props} handleClose={handleClose} />
        )}
      </Menu>
    </Box>
  )
}
