import { createTheme } from "@mui/material/styles"

// const background = "gainsboro"
const element = "whitesmoke"
// const backgroundtext = "black"
// const elementtext = "black"
// const altelementtext = "dimgray"
const buttoncolor = "#1976d2"

const theme = createTheme({
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: element,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: {
          backgroundColor: element,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: element,
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: buttoncolor,
            borderWidth: "2px",
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        },
        notchedOutline: {
          borderColor: "transparent",
        },
      },
    },
  },
})

export default theme
