import React, { useState } from "react"
import { Box } from "@mui/system"
import {
  ListItemIcon,
  ListItemText,
  Modal,
  Button,
  Grid,
  TextField,
  MenuItem,
  Typography,
  Divider,
  IconButton,
} from "@mui/material"
import LoginIcon from "@mui/icons-material/Login"
import LockIcon from "@mui/icons-material/Lock"
import CloseIcon from "@mui/icons-material/Close"
import Registration from "./Registration"
import ModalStyle from "../ModalStyle"
//import ForgotPassword from "./ForgotPassword"

export default function Navigation(props) {
  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const toggleLoginModal = (open) => (event) => setLoginModalOpen(open)
  const handleEmail = (event) => setEmail(event.target.value)
  const handlePassword = (event) => setPassword(event.target.value)
  const handleSuccessfulAuth = (data) => {
    localStorage.setItem("cocktail_curator_token", data.jwt)
    props.handleLogin(data.user)
    setLoginModalOpen(false)
    props.handleClose()
  }
  const handleSubmitLogin = async (event) => {
    event.preventDefault()
    try {
      const res = await fetch("/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      })
      const data = await res.json()
      if (res.ok) {
        handleSuccessfulAuth(data)
        setEmail("")
        setPassword("")
      } else {
        throw new Error(data.error || "Login Failed")
      }
    } catch (error) {
      alert(error.message)
    }
  }

  return (
    <>
      <MenuItem onClick={toggleLoginModal(true)}>
        <ListItemIcon>
          <LoginIcon />
        </ListItemIcon>
        <ListItemText primary="Log In" />
      </MenuItem>
      <Modal
        onKeyDown={(e) => e.stopPropagation()}
        open={loginModalOpen}
        onClose={toggleLoginModal(false)}
      >
        <Box sx={{ ...ModalStyle, textAlign: "center" }}>
          <Grid
            container
            sx={{ p: 2 }}
            spacing={2}
            justifyContent="center"
            alignItems="center"
            component="form"
            onSubmit={handleSubmitLogin}
          >
            <Grid item xs={12}>
              <Typography sx={{ pb: 1 }} variant="h5">
                Log In
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                id="form-email"
                fullWidth
                type="email"
                name="email"
                autoComplete="email"
                label="Email Address"
                value={email}
                onChange={handleEmail}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="form-password"
                fullWidth
                type="password"
                name="password"
                autoComplete="current-password"
                label="Password"
                value={password}
                onChange={handlePassword}
                required
              />
            </Grid>
            <Grid item xs="auto">
              <Button
                sx={{ mt: 1 }}
                size="large"
                variant="contained"
                type="submit"
                id="login-button"
              >
                <LockIcon fontSize="small" sx={{ pr: 1 }} />
                Log In
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider>
                <Typography variant="button">or</Typography>
              </Divider>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Registration {...props} handleSuccessfulAuth={handleSuccessfulAuth} />
            </Grid>
            {
              //<Grid item xs={12}>
              //  <ForgotPassword />
              //</Grid>
            }
          </Grid>
          <IconButton
            aria-label="close window"
            sx={{ position: "absolute", top: 10, right: 10 }}
            onClick={toggleLoginModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </>
  )
}
