import React, { Suspense } from "react"
import { Outlet } from "react-router-dom"
import NavBar from "../components/navigation/NavBar"

const Layout = (props) => {
  return (
    <>
      <NavBar {...props} />
      <Suspense>
        <Outlet />
      </Suspense>
    </>
  )
}

export default Layout
