const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "85%", sm: "500px" },
  height: "auto",
  bgcolor: "gainsboro",
  boxShadow: 24,
  padding: { xs: 1, md: 2 },
  borderRadius: 1,
}

export default ModalStyle
