import { Box, Button, Grid, IconButton, Modal, TextField, Typography } from "@mui/material"
import React, { Component } from "react"
import ModalStyle from "../ModalStyle"
import CloseIcon from "@mui/icons-material/Close"

export default class Registration extends Component {
  constructor(props) {
    super(props)

    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      registrationModalOpen: false,
    }

    this.handleRegister = this.handleRegister.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggleRegistrationModal = this.toggleRegistrationModal.bind(this)
  }

  toggleRegistrationModal(open) {
    this.setState({ registrationModalOpen: open })
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleRegister = async (event) => {
    event.preventDefault()
    const { first_name, last_name, email, password } = this.state
    const res = await fetch("/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        first_name: first_name,
        last_name: last_name,
        email: email,
        password: password,
      }),
    }).then((response) => response.json())
    if (res.errors) {
      alert(res.errors)
    } else {
      this.props.handleSuccessfulAuth(res)
      this.setState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        registrationModalOpen: false,
      })
    }
  }

  render() {
    return (
      <>
        <Button
          sx={{ mb: 1.5 }}
          size="large"
          variant="contained"
          id="register-modal-button"
          onClick={() => this.toggleRegistrationModal(true)}
        >
          Create Account
        </Button>
        <Modal
          hideBackdrop
          open={this.state.registrationModalOpen}
          onClose={() => this.toggleRegistrationModal(false)}
        >
          <Box
            sx={{ ...ModalStyle, textAlign: "center", boxShadow: 0 }}
            component="form"
            onSubmit={this.handleRegister}
          >
            <Grid container sx={{ p: 2 }} spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h5">Create an Account</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ pb: 1.5 }}>
                  Enter your information below and click register, to create an account.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  fullWidth
                  type="text"
                  name="first_name"
                  autoComplete="given-name"
                  label="First Name"
                  value={this.state.first_name}
                  onChange={this.handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  name="last_name"
                  autoComplete="family-name"
                  label="Last Name"
                  value={this.state.last_name}
                  onChange={this.handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="email"
                  name="email"
                  autoComplete="email"
                  label="Email Address"
                  value={this.state.email}
                  onChange={this.handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ mb: 1 }}
                  fullWidth
                  type="password"
                  name="password"
                  autoComplete="new-password"
                  label="Password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  required
                />
              </Grid>
              <Grid item xs="auto">
                <Button size="large" variant="contained" type="submit" id="register-button">
                  Register
                </Button>
              </Grid>
            </Grid>
            <IconButton
              aria-label="close window"
              sx={{ position: "absolute", top: 10, right: 10 }}
              onClick={() => this.toggleRegistrationModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Modal>
      </>
    )
  }
}
