import React, { lazy, useEffect, useState } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Layout from "./pages/Layout"
import "./index.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { ThemeProvider } from "@emotion/react"
import theme from "./theme"

const Home = lazy(() => import("./pages/Home"))
const MyBar = lazy(() => import("./pages/MyBar"))
const Admin = lazy(() => import("./pages/Admin"))
const Test = lazy(() => import("./pages/Test"))
const UserManagement = lazy(() => import("./pages/UserManagement"))
const UserAccount = lazy(() => import("./pages/UserAccount"))

function App() {
  const [loggedInStatus, setLoggedInStatus] = useState("NOT_LOGGED_IN")
  const [user, setUser] = useState({})

  const checkLoginStatus = () => {
    const token = localStorage.getItem("cocktail_curator_token")
    if (token) {
      fetch("/check_logged_in/", {
        headers: { "custom-user": `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.logged_in) {
            setLoggedInStatus("LOGGED_IN")
            setUser(response.user)
          } else {
            localStorage.removeItem("cocktail_curator_token")
            setLoggedInStatus("NOT_LOGGED_IN")
            setUser({})
          }
        })
    } else {
      return
    }
  }

  useEffect(() => {
    checkLoginStatus()
  }, [])

  const handleLogin = (data) => {
    setLoggedInStatus("LOGGED_IN")
    setUser(data)
  }

  const appProps = {
    user: user,
    loggedInStatus: loggedInStatus,
    handleLogin: handleLogin,
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout {...appProps} />}>
            <Route index element={<Home {...appProps} />} />
            {loggedInStatus === "LOGGED_IN" && (
              <>
                <Route path="Account" element={<UserAccount {...appProps} />} />
                <Route path="MyBar" element={<MyBar {...appProps} />} />
              </>
            )}
            {loggedInStatus === "LOGGED_IN" && user.admin && (
              <>
                <Route path="Admin" element={<Admin {...appProps} />} />
                <Route path="UserManagement" element={<UserManagement {...appProps} />} />
                <Route path="Test" element={<Test {...appProps} />} />
              </>
            )}
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

const rootElement = document.getElementById("root")
createRoot(rootElement).render(<App />)
