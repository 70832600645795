import * as React from "react"
import { Box, AppBar, Toolbar } from "@mui/material"
import NavMenu from "./NavMenu"
import UserMenu from "./UserMenu"
import { ReactComponent as LogoSvg } from "./Logo.svg"

export default function ButtonAppBar(props) {
  return (
    <Box sx={{ flexGrow: 1, pb: 2 }}>
      <AppBar position="static" sx={{ backgroundColor: "whitesmoke" }} elevation={0}>
        <Toolbar>
          <NavMenu {...props} />
          <LogoSvg />
          <UserMenu {...props} />
        </Toolbar>
      </AppBar>
    </Box>
  )
}
